import type { PropsWithChildren } from "react";

export function MobileConstraints(props: PropsWithChildren) {
  return (
    <div className="bg-neutral-200">
      <div
        {...props}
        className="max-w-xl w-screen h-screen m-auto relative bg-white"
      />
    </div>
  );
}
